import ImageAlex from '@/assets/images/partners/partners-alex.png';
import ImageAvalanche from '@/assets/images/partners/partners-avalanche.png';
import ImageAws from '@/assets/images/partners/partners-aws.png';
import ImageBitTrade from '@/assets/images/partners/partners-bittrade.png';
import ImageChainlink from '@/assets/images/partners/partners-chainlink.png';
import ImageCoinplug from '@/assets/images/partners/partners-coinplug.png';
import ImageConflux from '@/assets/images/partners/partners-conflux.png';
import ImageCore from '@/assets/images/partners/partners-core.png';
import ImageCube from '@/assets/images/partners/partners-cube.png';
import ImageDelight from '@/assets/images/partners/partners-delight.png';
import ImageDespread from '@/assets/images/partners/partners-despread.png';
import ImageEl from '@/assets/images/partners/partners-el.png';
import ImageJoc from '@/assets/images/partners/partners-joc.png';
import ImageKanalabs from '@/assets/images/partners/partners-kanalabs.png';
import ImageKddi from '@/assets/images/partners/partners-kddi.png';
import ImageKica from '@/assets/images/partners/partners-kica.png';
import ImageMch from '@/assets/images/partners/partners-mch.png';
import ImageNansen from '@/assets/images/partners/partners-nansen.png';
import ImageNeo from '@/assets/images/partners/partners-neo.png';
import ImageOasys from '@/assets/images/partners/partners-oasys.png';
import ImageOkj from '@/assets/images/partners/partners-okj.png';
import ImageOpensea from '@/assets/images/partners/partners-opensea.png';
import ImageOzys from '@/assets/images/partners/partners-ozys.png';
import ImagePacificMeta from '@/assets/images/partners/partners-pacificmeta.png';
import ImagePlanz from '@/assets/images/partners/partners-planz.png';
import ImagePolygon from '@/assets/images/partners/partners-polygon.png';
import ImageSix from '@/assets/images/partners/partners-six.png';
import ImageSlash from '@/assets/images/partners/partners-slash.png';
import ImageStacks from '@/assets/images/partners/partners-stacks.png';
import ImageSynfutures from '@/assets/images/partners/partners-synfutures.png';
import ImageTheori from '@/assets/images/partners/partners-theori.png';
import ImageThepol from '@/assets/images/partners/partners-thepol.png';
import ImageXlink from '@/assets/images/partners/partners-xlink.png';

export const PARTNERS = [
  { name: 'Aws', image: ImageAws },
  { name: 'Nansen', image: ImageNansen },
  { name: 'Kddi', image: ImageKddi },
  { name: 'PacificMeta', image: ImagePacificMeta },
  { name: 'Okj', image: ImageOkj },
  { name: 'BitTrade', image: ImageBitTrade },
  { name: 'Oasys', image: ImageOasys },
  { name: 'Mch', image: ImageMch },
  { name: 'Planz', image: ImagePlanz },
  { name: 'Core', image: ImageCore },
  { name: 'Joc', image: ImageJoc },
  { name: 'Slash', image: ImageSlash },
  { name: 'Synfutures', image: ImageSynfutures },
  { name: 'Thepol', image: ImageThepol },
  { name: 'Neo', image: ImageNeo },
  { name: 'Conflux', image: ImageConflux },
  { name: 'Stacks', image: ImageStacks },
  { name: 'Theori', image: ImageTheori },
  { name: 'Alex', image: ImageAlex },
  { name: 'Xlink', image: ImageXlink },
  { name: 'Cube', image: ImageCube },
  { name: 'Delight', image: ImageDelight },
  { name: 'Ozys', image: ImageOzys },
  { name: 'El', image: ImageEl },
  { name: 'Avalanche', image: ImageAvalanche },
  { name: 'Six', image: ImageSix },
  { name: 'Despread', image: ImageDespread },
  { name: 'Kica', image: ImageKica },
  { name: 'Opensea', image: ImageOpensea },
  { name: 'Polygon', image: ImagePolygon },
  { name: 'Kanalabs', image: ImageKanalabs },
  { name: 'Coinplug', image: ImageCoinplug },
  { name: 'Chainlink', image: ImageChainlink },
];
