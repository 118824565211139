import {
  isNormalPositive,
  useAssetInfoMap,
} from '@bifrost-platform/bifront-sdk-react-biholder';
import { BN } from '@bifrost-platform/bifront-sdk-react-wallet';
import { useCallback, useMemo } from 'react';
import useSWR from 'swr';
import { SWR_CONFIG } from '@/configs/swr';
import useGetCurrencyString from '@/hooks/store/useGetCurrencyString';
import { formatNumber } from '@/libs/formatNumber';
import BtcfiMarket from '@/types/BtcfiMarket';
import useEnv from '../useEnv';

const fetcher = (url: string) => fetch(url).then((res) => res.json());

const useBtcfiMarket = () => {
  const { isTestnet } = useEnv();

  const { currencyId } = useGetCurrencyString();

  const { krwByUsd } = useAssetInfoMap();

  const { data, isLoading, error, mutate } = useSWR<BtcfiMarket[]>(
    `/api/getBtcfiMarket?isTestnet=${isTestnet}`,
    fetcher,
    SWR_CONFIG
  );

  const tvlValue = useMemo(() => {
    let result = '0';

    if (data?.length) {
      const amountValue = data.reduce(
        (pre, cur) =>
          pre.plus(
            (isNormalPositive(cur.deposit_value, true)
              ? cur.deposit_value
              : 0) ?? 0
          ),
        new BN(0)
      );

      result = formatNumber(
        new BN(amountValue).multipliedBy(
          currencyId === 'krw' ? krwByUsd ?? 1 : 1
        )
      );
    }

    return result;
  }, [currencyId, data, krwByUsd]);

  const sync = useCallback(() => {
    mutate();
  }, [mutate]);

  return {
    tvlValue,
    data: data ?? [],
    isLoading,
    loadError: error,
    sync,
  };
};

export default useBtcfiMarket;
